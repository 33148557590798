<template>
  <v-row>
    <v-col cols="6" class="half-image-login"> </v-col>
    <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="half-form-login">
      <v-row class="box">
        <v-col cols="12" class="mt-0 pt-0 pb-2">
          <img src="../../assets/img/okan_4.png" class="logo-login" />
          <h1>{{ $translate("login.welcomeToOkan") }}</h1>
          <p class="sub-title mb-0">{{ $translate("login.accessYourWorkspace") }}</p>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-row v-show="!directory" justify="center">
            <v-col cols="12" xl="8" lg="8" md="8" sm="8" xs="12" class="px-0 mx-0 butons_class">
              <v-btn class="form-login-btn " block rounded :loading="loadingAll" @click="directory = true"
                :class="hidden ? 'btn-hidden' : 'btn-visibility'">
                {{ $translate("login.loginWithNetworkUser") }}
              </v-btn>
              <div class=" ml-0  ml-sm-0">
                <div id="signin_button" class="form-login-google"></div>
              </div>
              <div class=" ml-0  ml-sm-0">
                <div id="signin_button_office-365" class="form-login-office-365">
                  <v-btn class="form-login-btn-white" @click="authOffice365">
                    <img src="../../assets/img/microsoft-logo.svg" alt="Microsoft logo" class="icono" />
                    {{ $translate("login.continueWithMicrosoft") }}
                  </v-btn>

                </div>
              </div>
            </v-col>
          </v-row>

          <v-form v-show="directory" ref="form" class="form-login">
            <v-row justify="center">
              <v-col cols="12" xl="8" lg="8" md="8" sm="8" xs="12" class="pb-0 px-0 mx-0">
                <div class="label-login">
                  <h2>
                    {{ $translate("login.networkUser") }} &nbsp;

                    <v-tooltip max-width="360" bottom color="#fff">
                      <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" width="20" src="../../assets/img/message-question.svg"
                          alt="info login" />
                      </template>
                      <p class="login-text-tooltip mt-3 pb-0 mb-0">
                        {{ $translate("login.networkUserDescription") }}
                      </p>
                      <br />
                      <img alt="manual rapido" width="330" src="../../assets/img/usuario-red-login.gif" />
                    </v-tooltip>
                  </h2>
                </div>
                <v-text-field @keyup.enter="ldap()" class="form-login-textfield ml-0 ml-xl-0 ml-lg-0 ml-md-5 ml-sm-0"
                  :rules="[(v) => !!v || 'El correo electrónico es requerido.']" regular dark
                  :placeholder="$translate('login.enterNetworkUser')" prepend-inner-icon="mdi-account-circle-outline"
                  v-model="form.user">
                </v-text-field>
                <div class="label-login">
                  <h2>{{ $translate("login.password") }}</h2>
                </div>
                <v-text-field @keyup.enter="ldap()" class="form-login-textfield ml-0 ml-xl-0 ml-lg-0 ml-md-5 ml-sm-0"
                  :rules="[(v) => !!v || 'La contraseña es requerida.']" regular dark
                  :placeholder="$translate('login.enterPassword')" prepend-inner-icon="mdi-onepassword left" v-model="form.pass"
                  :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="show = !show">
                </v-text-field>
                <v-btn class="form-login-btn mb-5 mt-3 mr-5 ml-0 ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0" block rounded color=""
                  :loading="loadingAll" @click="(e) => ldap(e)" type="submit">
                  {{ $translate("login.accessWithNetworkUser") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>

    <Alert :open="open" :text="alert.txt" :title="alert.title" :type="alert.type" @close="open = false">
    </Alert>

    <v-dialog max-width="400" v-model="modal.sms">
      <v-card>
        <v-card-title>
          <v-row class="my-0 mx-0 py-0 px-0 ">
            <v-col cols="11" class="my-0 mx-0 py-0 px-0 ">
              <v-layout justify-center>
                <p class="mb-0 pt-1" style="color: #466be3;">
                  <strong> {{ title }} </strong>
                </p>
              </v-layout>
            </v-col>
            <v-col cols="1" class="my-0 mx-0 py-0 px-0">
              <v-btn small fab icon @click="modal.sms = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p style="font-size: 16px">{{ sms }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Alert :open="message.dialog" :text="message.sms" :title="message.title" :type="message.type"
      :redirect="message.redirect" @close="message.dialog = false">
    </Alert>




  </v-row>
</template>
<script>
import Api from "@/config/api";
import { login } from "../../config/auth";
import swal from "@/mixins/swal";
import Alert from "../Alert.vue";

export default {
  mixins: [swal],
  name: "Login",
  components: {
    Alert,
  },
  data() {
    return {
      alert: {
        txt: "",
        title: "",
        type: "",
      },
      open: false,
      directory: false,
      modal: {
        sms: false,
      },
      title: "",
      sms: "",
      loadingAll: false,
      errors: {
        user: false,
        pass: false,
      },
      form: {
        user: "",
        pass: "",
      },
      show: false,
      hidden: false,
      device: '',

      message: {
        dialog: false,    // Controla si el alert está abierto
        sms: '',          // El mensaje a mostrar
        title: '',        // El título del alert
        type: '',         // El tipo de mensaje ('success', 'error', etc.)
        redirect: '',     // URL a la que redirigir (opcional)
      },

    };
  },
  watch: {},
  methods: {
    ldap(e) {
      e.preventDefault();
      console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        this.form.origen = this.device;
        this.loadingAll = true;
        Api.Auth()
          .ldap(this.form)
          .then((res) => {
            this.loadingAll = false;
            console.log(`Respuesta -- ${JSON.stringify(res.data)}`);
            if (res.data.cod == 0) {
              login(res.data.data);
              window.location.href =
                process.env.VUE_APP_REDIRECT_OKAN + res.data.data;
              this.alert.title = "Excelente";
              this.alert.txt = res.data.message;
              this.alert.type = "success";
              this.open = true;
            } else {
              this.alert.title = "¡Oh no!";
              this.alert.txt = res.data.message;
              this.alert.type = "error";
              this.open = true;
            }
          })
          .catch((error) => {
            this.loadingAll = false;
            this.alert.title = "¡Oh no!";
            this.alert.txt = error.response.data.message;
            this.alert.type = "error";
            this.open = true;
            console.log(error);
          });
      } else {
        this.alert.title = "¡Oh no!";
        this.alert.txt = "Por favor ingrese sus credenciales";
        this.alert.type = "error";
        this.open = true;

        // this.title = "¡INFO!";
        // this.sms = "Por favor ingrese sus credenciales";
        // this.modal.sms = true;
        // this.swal("Por favor ingrese sus credenciales", "error");
      }
    },
    parseJwt(token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    },
    google() {
      this.loadingAll = true;
      this.$gapi.login().then(({ currentUser }) => {
        // var profileU = currentUser.getBasicProfile();
        let google = currentUser;
        let llaves = Object.keys(google);
        let token = "";
        Object.keys(llaves).forEach((element) => {
          if (
            typeof google[`${Object.keys(google)[element]}`].id_token !==
            "undefined"
          ) {
            token = google[`${Object.keys(google)[element]}`].id_token;
          }
        });

        var data = {
          email: this.parseJwt(token) != null ? this.parseJwt(token).email : null,
          token: token,
          name: this.parseJwt(token) != null ? this.parseJwt(token).name : null,
          origen: this.device
        };

        Api.Auth()
          .google(data)
          .then((res) => {
            if (res.data.cod == 0) {
              login(res.data.data);
              sessionStorage.setItem("pruebaToken1", res.data.data);
              localStorage.setItem("pruebaToken", res.data.data);
              window.location.href =
                process.env.VUE_APP_REDIRECT_OKAN + res.data.data;

              this.alert.title = "Excelente";
              this.alert.txt = res.data.message;
              this.alert.type = "success";
              this.open = true;

              // this.title = "Muy bien";
              // this.sms = res.data.message;
              // this.modal.sms = true;
              // console.log(res.data.data);
              // window.location.href = 'https://okan.dev.tars.tools? =' + res.data.data;
              // this.swal(res.data.message, "success");
            } else {
              this.alert.title = "¡Oh no!";
              this.alert.txt = res.data.message;
              this.alert.type = "error";
              this.open = true;
            }
          })
          .catch((error) => {
            this.loadingAll = false;
            this.alert.title = "¡Oh no!";
            this.alert.txt = error.response.data.message;
            this.alert.type = "error";
            this.open = true;
            // this.swal(error.response.data.message, "error");
          });
      });
    },
    recover() {
      this.alert.title = "¡Oh no!";
      this.alert.txt = "Acción no disponible por el momento";
      this.alert.type = "error";
      this.open = true;
    },

    handleCredentialResponse(response) {
      var token = response.credential;
      var data = {
        email: this.parseJwt(token) != null ? this.parseJwt(token).email : null,
        token: token,
        name: this.parseJwt(token) != null ? this.parseJwt(token).name : null,
        origen: this.device
      };
      Api.Auth()
        .google(data)
        .then((res) => {
          if (res.data.cod == 0) {
            login(res.data.data);
            sessionStorage.setItem("pruebaToken1", res.data.data);
            localStorage.setItem("pruebaToken", res.data.data);
            window.location.href =
              process.env.VUE_APP_REDIRECT_OKAN + res.data.data;

            this.alert.title = "Excelente";
            this.alert.txt = res.data.message;
            this.alert.type = "success";
            this.open = true;
          } else {
            this.alert.title = "¡Oh no!";
            this.alert.txt = res.data.message;
            this.alert.type = "error";
            this.open = true;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          this.alert.title = "¡Oh no!";
          this.alert.txt = error.response.data.message;
          this.alert.type = "error";
          this.open = true;
        });

    },

    authOffice365() {
      Api.fetchAuth() // Llamada a la función fetchAuth
        .then(auth => {
          console.log('Auth object:', auth); // Muestra el objeto auth para depuración
          return auth.office365(); // Intenta acceder a office365 desde auth
        })
        .then((res) => {
          console.log('Response from office365:', res); // Muestra la respuesta para depuración
          if (res && res.data && res.data.length > 0) {
            const url = res.data; // Asume que la URL está en el primer elemento del array de datos
            window.location.href = url; // Redirige al usuario a la URL
          } else {
            console.error('No URL found in the response data');
            this.showErrorAlert('No se encontró una URL válida en la respuesta.');
          }
        })
        .catch(error => {
          console.error('Error al autenticar:', error);
          this.showErrorAlert('Hubo un error al autenticar con Office 365. Intenta de nuevo.');
        });
    },

    // Método para mostrar el mensaje de error en el Alert
    showErrorAlert(message) {
      this.message.title = '¡Oh no!';       // Título del mensaje
      this.message.sms = message;           // El mensaje a mostrar
      this.message.type = 'error';          // El tipo de mensaje
      this.message.dialog = true;           // Abre el diálogo
    },

    detectarDispositivo() {
      const esMovil = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      if (esMovil) {
        this.device = "Movil";
      } else {
        this.device = "PC";
      }
    },
  },
  mounted: function () {
    let googleScript = document.createElement("script");
    googleScript.src = "https://accounts.google.com/gsi/client";
    document.head.appendChild(googleScript);
    
    // const buttonText = navigator.language.startsWith("es") ? "sign_in_with" : "sign_in_with";
    window.addEventListener("load", () => {
      //console.log(window.google);
      const userLocale = navigator.language || navigator.userLanguage;
      const isSpanish = userLocale.startsWith("es");

      const locale = isSpanish ? "es" : "en";
      const text = isSpanish ? "continue_with" : "sign_in_with";

      console.log(locale,text)
      window.google.accounts.id.initialize({
        client_id:
          "507028575910-u2jvgncdop8bjcq5ldcljbpdt1s5q910.apps.googleusercontent.com",
        //34728626285-18hduqd90h6gu04d606t9bvt57n8aerk.apps.googleusercontent.com
        //507028575910-u2jvgncdop8bjcq5ldcljbpdt1s5q910.apps.googleusercontent.com
        // "507028575910-h3e21molou5he7cq75k5slkot9hbpt9q.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("signin_button"),
        {
          theme: "outline",
          size: "large",
          shape: "pill",
          width: "300",
          locale: locale, // Ajuste de idioma
          text: text, // Ajuste del texto según el idioma
        } // customization attributes
      );
    });
  },
  created() {
    this.detectarDispositivo();
  },
};
</script>
<style scoped>
.butons_class {
  display: flex;
  flex-direction: column;
  /* align-content: center; */
  align-items: center;
  gap: 20px;
}

.div-logo {
  width: 40px;
  height: 24px;
  margin: 10px;
  background-color: #fff;
}

.box {
  max-width: 90%;
  border-radius: 10px;
  backdrop-filter: blur(1px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding-bottom: 10px;
  margin: 30px !important;
}

.v-btn {
  text-transform: none !important;
}

.login-text {
  text-align: center;
}

.half-image-login {
  background-image: url(../../assets/img/left-image-new-2.jpg);
  background-size: cover;
}

.half-form-login {
  background: linear-gradient(315deg, #141b33 20%, #2e3e75 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-login {
  /* width: 700px;
  height: 300px; */
  width: 230px;
  height: 230px;
}

.label-login {
  font-family: "AsapRegular" !important;
  text-align: left;
  padding-bottom: 0px;
}

h1 {
  font-family: "AsapRegular" !important;
  color: #f2f4ff;
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;
}

h2 {
  font-family: "AsapRegular" !important;
  color: #f2f4ff;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
}

.sub-title {
  color: #ffffff;
  font-weight: 300;
  font-size: 22px;
  line-height: 37px;
  font-size: "ProximaNovaRegular";
}

.form-login-textfield {
  padding-bottom: 10px;
}

.form-login-btn {
  height: 40px;
  min-width: 300px !important;
  min-width: 300px !important;
  background: linear-gradient(179.5deg, #466be3 5%, #324ca1 100%);
  box-shadow: 0px 2px 8px rgba(50, 76, 161, 0.5),
    0px 4px 24px rgba(50, 76, 161, 0.3);
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  height: 8.5%;
  font-size: 13px;
}

.form-login-btn-white {
  height: 40px;
  min-width: 300px !important;
  min-width: 300px !important;
  /* background: linear-gradient(179.5deg, #fff 5%, #fff 100%) !important; */
  background: #fff !important;
  box-shadow: 0px 2px 8px rgba(50, 76, 161, 0.5),
    0px 4px 24px rgba(50, 76, 161, 0.3);
  color: #000;
  border-radius: 20px;
  height: 8.5%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  letter-spacing: normal;

}

.form-login-btn-white>>>span {

  font-family: "Google Sans", arial, sans-serif !important;
}

.form-login-btn-white .icono {
  width: 24px;
  /* Ajusta el tamaño de la imagen */
  height: 24px;
  margin-right: 10px;
  /* Espacio entre el texto y la imagen */
}

.form-login-olvide {
  font-style: normal;
  font-size: 15px;
  color: #889de2;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: bold;
}



.login-text-tooltip {
  font-family: "AsapRegular";
  font-weight: 600;
  font-size: 16px;
  color: #353535;
}

.btn-visibility {
  visibility: visible;
}

.btn-hidden {
  visibility: hidden;
}

@font-face {
  font-family: "AsapRegular";
  src: url("./../../assets/fonts/Asap/Asap-Regular.ttf");
}

@font-face {
  font-family: "ProximaNovaRegular";
  src: url("./../../assets/fonts/ProximaNova/ProximaNova-Regular.ttf");
}

/* LAPTOP 4k */

@media (max-width: 1900px) {}

/* LAPTOP LARGE */

@media (max-width: 1600px) {}

/* LAPTOP LARGE */

@media (max-width: 1400px) {}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {}

/* TABLET */

@media (max-width: 1023px) {
  .half-image-login {
    display: none;
  }

  .sub-title {
    font-size: 30px;
    font-weight: bold;
    font-family: "AsapRegular" !important;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .half-image-login {
    display: none;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    font-family: "AsapRegular" !important;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .half-image-login {
    display: none;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    font-family: "AsapRegular" !important;
    margin-bottom: 0px;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .half-image-login {
    display: none;
  }

  .form-login-btn {
    font-size: 10px;
  }

  .form-login-google {
    font-size: 10px;
  }

  .form-login-olvide {
    font-size: 11px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    font-family: "AsapRegular" !important;
    margin-bottom: 0px;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}
</style>
